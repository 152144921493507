import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import userManager, { loadUserFromStorage } from "../services/userService";
import AuthProvider from "../utils/authProvider";
import "allotment/dist/style.css";
import "./App.scss";
import DagableMainSideBar from "../components/layout/dagableMainSideBar";
import { ProSidebarProvider } from 'react-pro-sidebar';
import { ToastContainer } from "react-toastify";
import CookieConsent from "react-cookie-consent";
import { CookiesProvider } from "react-cookie";
import WelcomeModal from "../components/layout/welcomePopup";

function App() {
    useEffect(() => {
        loadUserFromStorage(store);
    }, []);

    return (
        <CookiesProvider>
            <ProSidebarProvider>
                <Provider store={store}>
                    <AuthProvider userManager={userManager} store={store}>
                        <Router >
                            <DagableMainSideBar />
                            <WelcomeModal />
                        </Router>
                    </AuthProvider>
                </Provider>
                <ToastContainer />
                <CookieConsent buttonClasses="btn btn-dagable me-3 mb-2" disableButtonStyles={false} buttonText="Agree">This website uses cookies to enhance the user experience.</CookieConsent>
            </ProSidebarProvider>
        </CookiesProvider>
    );
}

export default App;
